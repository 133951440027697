<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
    <span class="mg-r-10">
          <p-dropdown [options]="plcList"

                      placeholder="Sélectionner un point de livraison..."
                      [style]="{'width':'300px'}"
                      (onChange)="changePlcPortail($event)"
                      [(ngModel)]="selectedPlc"
                      showClear="true"
                      optionLabel="libelle"
                      filter="true">
          </p-dropdown>
    </span>

      <span class="mg-r-5">
      <p-calendar
        firstDayOfWeek="1"
        dateFormat="'{{getWeekLabel(week)}}'"


        [numberOfMonths]="2"
        [style]="{'width':'280px'}"
        (onSelect)="changeWeek($event)"
        (onFocus)="getMonthDates($event)"
        (onMonthChange)="getMonthDates($event)"
        (onYearChange)="getMonthDates($event)"
        [readonlyInput]="true"
        [(ngModel)]="selectedDate"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true"
        [disabledDays]="disabledDays"
        [minDate]="dateMin"
      >
            <ng-template pTemplate="date" let-date>
              <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                             [dateCreationMenu]="utils.isDateInList(date,datesCreationMenus)"></yo-date-dispo>
            </ng-template>
          </p-calendar>
    </span>
    </div>
  </dxi-item>
</dx-toolbar>
<div class="d-flex flex-wrap mg-t-14"
     style="width: calc( 100vw - 70px); height: calc( 100vh - 150px ); max-height: calc( 100vh - 150px ); overflow: auto;">

  <div class="max-width-900 min-width-900"
       style="height: calc( 100vh - 150px ); max-height: calc( 100vh - 150px ); overflow: auto;">
    <yo-generic-prestations-semaine
      (onEmitPlcRepasAgain)="onEmitPlcRepasAgain($event)"
      [typeEffectif]="typeEffectif"
      [repasPlcWeekList]="plcWeek?.repasList"
      [configPortailPlc]="configPortailPlc">
    </yo-generic-prestations-semaine>
    <div class="card-footer mg-t-14">
    <span class="font-weight-normal font-12">
      <div class="text-left">
        <i class="fas fa-square save-color mg-r-5 border"></i>Effectifs en cours de saisie
      </div>
      <div class="text-left">
        <i class="fas fa-square site-secondaire-color mg-r-5 border"></i>Menus en cours de saisie
      </div>
      <div class="text-left">
        <i class="fas fa-square checked-color mg-r-5 border"></i>Menus validés = Saisie des effectifs possible
      </div>
            <div class="text-left">
        <i class="fas fa-square invoice-color mg-r-5 border"></i>Effectifs faisant l'objet d'une facture
      </div>
      <div class="text-left">
        <i class="fas fa-square white mg-r-5 border"></i>Aucun menu
      </div>
    </span>
    </div>
  </div>
  <div class="mg-l-20">
    <yo-generic-repas
      [configPortailPlc]="configPortailPlc"
      [typeEffectif]="typeEffectif"
      [plcRepas]="plcRepas">
    </yo-generic-repas>
  </div>
</div>





