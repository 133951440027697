<ng-container *ngIf="canDisplayGrid()">

  <h5 class="first mg-b-38">
    <span class="badge badge-primary mg-r-5">{{infoPlcRepas.repas.libelle | uppercase}}</span>
    <span class="badge badge-success mg-r-5">{{infoPlcRepas.cmcContrainteAlim.cmcLibelle | uppercase}}</span>
    <span class="badge badge-warning mg-r-5">{{infoPlcRepas.cmcContrainteAlim.regimeLibelle | uppercase}}</span>
    <span class="badge badge-info mg-r-5">{{infoPlcRepas.dateMenu  | date:'EEEE d LLLL'  | uppercase}}</span>
  </h5>

  <div [style.width.px]="800">
    <p-table
      styleClass="p-datatable-sm"
      [scrollable]="true"
      [value]="plcRepas.rows">

      <!--HEADER-->
      <ng-template pTemplate="header">
        <tr>
          <th [style.width.px]="150">Composante Repas</th>
          <th>Plat</th>
          <th title="Taux de prise en %">Tdp %</th>
          <th>
            <!--          TYPE EFFECTIF-->
            <ng-container *ngTemplateOutlet="tplThEffectif"></ng-container>
          </th>

        </tr>
      </ng-template>

      <!--BODY-->
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr [class.modification-manuelle]="rowData?.plat?.modificationManuelle"
            [class.disable]="rowData.cmcrPlcDate.statutMenuCode != STATUTS_MENUS.SAISIE_EFFECTIFS">

          <td [style.width.px]="150">
            {{rowData.decoupageRepas.libelle}} {{rowData.ordre}}
          </td>


          <td class="cursor" (click)="chooseAction(menuActions,$event,rowData, plcRepas)">
            <p-menu appendTo="body" #menuActions [model]="actions" [popup]="true"></p-menu>
            <ng-container *ngIf="!utils.isNullOrEmpty(rowData.plat)">

              <div [title]="getHistoLabel(rowData.plat)"
                   style="width: 100%">
                <ng-container *ngIf="rowData.plat?.produitDeclinaison?.actif">
                  <span>
                    {{rowData?.plat?.produitDeclinaison?.libelle}}
                  </span>

                </ng-container>
                <ng-container *ngIf="!rowData.plat?.produitDeclinaison?.actif">
                  &nbsp;{{rowData?.plat?.produitDeclinaison?.libelle}} <i title="Plat inactif" class="fas fa-exclamation-triangle danger-color mg-r-5"></i>
                </ng-container>

              </div>
            </ng-container>

          </td>


          <td class="text-right">
            <ng-container *ngTemplateOutlet="tplTauxDePrise;context:{row:rowData}"></ng-container>
          </td>
          <td class="text-right">
            <ng-container *ngTemplateOutlet="tplEffectif;context:{row:rowData}"></ng-container>
          </td>

        </tr>

      </ng-template>

      <!--SUMMARY-->
      <ng-template pTemplate="summary">
        <tr>
          <td colspan="4">
            <div class="font-weight-normal font-12 text-left">
              <i class="fas fa-square edit-color mg-r-5 border"></i>Plat ajouté/modifié/supprimé manuellement.
            </div>
          </td>
        </tr>
      </ng-template>


    </p-table>
  </div>

</ng-container>

<!--TEMPLATE TH TYPE EFFECTIF-->
<ng-template #tplThEffectif>
  <ng-container [ngSwitch]="typeEffectif">
    <ng-container *ngSwitchCase="'1'">
      <span title="Effectif prévisionnel">Eff. prév.</span>
    </ng-container>

    <ng-container *ngSwitchCase="'2'">
      <span title="Effectif pour la fabrication">Eff. fab.</span>
    </ng-container>

    <ng-container *ngSwitchCase="'3'">
      <span title="Effectif pour la facturation">Eff. factu.</span>
    </ng-container>
  </ng-container>
</ng-template>

<!--TEMPLATE  EFFECTIF-->
<ng-template #tplEffectif let-row="row">

  <ng-container *ngIf="!utils.isNullOrEmpty(row.plat)">

    <ng-container [ngSwitch]="typeEffectif">

      <ng-container *ngSwitchCase="'1'">

        <input pInputText pKeyFilter="pint"
               min="0"
               [disabled]="!plcRepas.canModifyEffectif"
               [(ngModel)]="row.plat.effectifPrevisionnel"
               (click)="$event.stopPropagation()"
               (keydown)="changeEffectif($event,row.plat,'effectifPrevisionnel')"
               class="cell text-right bold "
               [class.arrondi]="row.plat.aEffectifPrevisionnel"
               [class.disable]="isInputDisabled(row)"

        >

      </ng-container>

      <ng-container *ngSwitchCase="'2'">

        <input pInputText pKeyFilter="pint"
               min="0"
               [disabled]="!plcRepas.canModifyEffectif"
               [(ngModel)]="row.plat.effectifFabrication"
               (click)="$event.stopPropagation()"
               (keydown)="changeEffectif($event,row.plat,'effectifFabrication')"
               class="cell text-right bold "
               [class.arrondi]="row.plat.aEffectifFabrication"
               [class.disable]="isInputDisabled(row)"
        >

      </ng-container>

      <ng-container *ngSwitchCase="'3'">

        <input pInputText pKeyFilter="pint"
               min="0"
               [disabled]="!plcRepas.canModifyEffectif"
               [(ngModel)]="row.plat.effectifFacture"
               (click)="$event.stopPropagation()"
               (keydown)="changeEffectif($event,row.plat,'effectifFacture')"
               class="cell text-right bold"
               [class.arrondi]="row.plat.aEffectifFacture"
               [class.disable]="isInputDisabled(row)"
        >

      </ng-container>


    </ng-container>


  </ng-container>
</ng-template>


<!--TEMPLATE  TAUX DE PRISE-->
<ng-template #tplTauxDePrise let-row="row">

  <ng-container *ngIf="!utils.isNullOrEmpty(row.plat)">

    <ng-container [ngSwitch]="typeEffectif">
      <ng-container *ngSwitchCase="'1'">

        <input pInputText pKeyFilter="pnum"
               min="0"
               [disabled]="!plcRepas.canModifyEffectif"
               [class.disable]="isInputDisabled(row)"
               [(ngModel)]="row.plat.tpEffectifPrevisionnel"
               (click)="$event.stopPropagation()"
               (keydown)="changeTauxDePrise($event,row.plat,'tpEffectifPrevisionnel')"
               class="cell text-right  bold">

      </ng-container>

      <ng-container *ngSwitchCase="'2'">

        <input pInputText pKeyFilter="pnum"
               min="0"
               [disabled]="!plcRepas.canModifyEffectif"
               [class.disable]="isInputDisabled(row)"
               [(ngModel)]="row.plat.tpEffectifFabrication"
               (click)="$event.stopPropagation()"
               (keydown)="changeTauxDePrise($event,row.plat,'tpEffectifFabrication')"
               class="cell text-right bold">

      </ng-container>

      <ng-container *ngSwitchCase="'3'">

        <input pInputText pKeyFilter="pnum"
               min="0"
               [disabled]="!plcRepas.canModifyEffectif"
               [class.disable]="isInputDisabled(row)"
               [(ngModel)]="row.plat.tpEffectifFacture"
               (click)="$event.stopPropagation()"
               (keydown)="changeTauxDePrise($event,row.plat,'tpEffectifFacture')"
               class="cell text-right  bold">

      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<!--TEMPLATE PLAT-->
<ng-template #tplPlat let-rowData="rowData" let-menuActions="menuActions">


  <ng-container *ngIf="!utils.isNullOrEmpty(rowData.plat)">
    {{rowData?.plat?.produitDeclinaison?.libelle}}
  </ng-container>

  <ng-container *ngIf="utils.isNullOrEmpty(rowData.plat)">

  </ng-container>

</ng-template>

<!--DIALOG RECHERCHE PLATS-->
<yo-dialog-recherche-plats></yo-dialog-recherche-plats>
