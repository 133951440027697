// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]7.0-rc-11324(15198119b0)-C25/02/2025-17:24:18-B25/02/2025-17:29:52' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]7.0-rc-11324(15198119b0)-C25/02/2025-17:24:18-B25/02/2025-17:29:52",
  branch: "master",
  latestTag: "7.0-rc",
  revCount: "11324",
  shortHash: "15198119b0",
  longHash: "15198119b0538a8e5465177329acdbac0795d6d7",
  dateCommit: "25/02/2025-17:24:18",
  dateBuild: "25/02/2025-17:29:52",
  buildType: "Ansible",
  } ;
